import React from 'react'
import styled from 'styled-components'
import { theme } from '../util/style'

export default ({ children }) => {
  return <List>{children}</List>
}

const List = styled.ul`
  margin: 0 0 1em 0;
  padding: 0;
  li {
    border-bottom: 1px solid ${theme.colors.grey2};
    list-style-type: none;
    margin: 0;
    padding: 0.2em 0 0.2em 0;
  }
  @media (max-width: 800px) {
    li {
      padding: 0.4em 0 0.4em 0;
    }
  }
`
